import { Trans } from "@lingui/macro";
import { ConsentManagerBuilder } from "@ht-sdks/consent-manager";
import {
  CategoryPreferences,
  Destination,
} from "@ht-sdks/consent-manager/types/types";
import { ReactNode, useCallback, useContext, useState, useEffect } from "react";
import { Button } from "src/atoms/Button";
import { CloseButton } from "src/atoms/CloseButton";
import { Expandable } from "src/atoms/Expandable";
import { Toggle } from "src/atoms/Toggle";
import { AppCtx } from "src/contexts/app.context";
import { ChevronRightSmall } from "src/icons/ChevronRightSmall";
import {
  ConsentItem,
  ConsentItemDescription,
  ConsentItemHeader,
  ConsentItemTitle,
  ConsentList,
  Dialog,
  DialogBody,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogWrapper,
} from "./ConsentManagerBanner.styled";
import {
  categories,
  Categories,
  CONSENT_NOT_REQUIRED_LOCALES,
  isAdvertisingCategory,
  isFunctionalCategory,
} from "./utils";
import { useLockBodyScroll } from "src/hooks/useLockBodyScroll";
import Cookies from "js-cookie";
import { useSegment } from "src/hooks/useSegment";
import { HyperLink } from "src/atoms/HyperLink";
import { getI18nRoute } from "src/utils/i18nRoutes";

export const COOKIE_NAME = "otrium_cookie-tracking-preferences-v3";

type SectionProps = {
  displayToggle: boolean;
  name: ReactNode;
  description: ReactNode;
  disabled: boolean;
};

const displayedSections: Record<Categories, SectionProps> = {
  functional: {
    displayToggle: true,
    name: <Trans>consent_manager.functional_title</Trans>,
    description: <Trans>consent_manager.functional_body</Trans>,
    disabled: true,
  },
  analytics: {
    displayToggle: true,
    name: <Trans>consent_manager.analytics_title</Trans>,
    description: <Trans>consent_manager.analytics_body</Trans>,
    disabled: false,
  },
  marketing: {
    displayToggle: true,
    name: <Trans>consent_manager.marketing_title</Trans>,
    description: <Trans>consent_manager.marketing_body</Trans>,
    disabled: false,
  },
} as const;

export const handleMapCustomPreferences = (
  destinations: Destination[],
  preferences: CategoryPreferences
): {
  destinationPreferences: CategoryPreferences;
  customPreferences: CategoryPreferences;
} => {
  const customPreferences: CategoryPreferences = {};
  const destinationPreferences: CategoryPreferences = {};

  // Default unset preferences to true (for implicit consent)
  for (const preferenceName of Object.keys(preferences)) {
    const value = preferences[preferenceName];
    const convertedValue = typeof value === "boolean" ? value : true;

    customPreferences[preferenceName] = convertedValue;
  }

  for (const destination of destinations) {
    // Mark advertising destinations
    if (
      isAdvertisingCategory(destination.category) &&
      destinationPreferences[destination.id] !== false
    ) {
      destinationPreferences[destination.id] = customPreferences.advertising;
    }

    // Mark function destinations
    if (
      isFunctionalCategory(destination.category) &&
      destinationPreferences[destination.id] !== false
    ) {
      destinationPreferences[destination.id] = customPreferences.functional;
    }

    // Fallback to marketing
    if (!(destination.id in destinationPreferences)) {
      destinationPreferences[destination.id] = customPreferences.marketing;
    }
  }

  return { destinationPreferences, customPreferences };
};

export const ConsentSettings = ({
  destinations,
  preferences,
  setPreferences,
}: {
  destinations: Destination[];
  preferences: CategoryPreferences;
  setPreferences: (newPreferences: CategoryPreferences) => void;
}): JSX.Element => {
  const [expanded, setExpanded] = useState(
    categories.reduce((map, section) => {
      map[section] = false;
      return map;
    }, {} as Record<Categories | "essentials", boolean>)
  );

  return (
    <ConsentList>
      {categories.map((sectionType) => {
        const section = displayedSections[sectionType];
        return (
          <ConsentItem key={sectionType}>
            <ConsentItemHeader>
              <ConsentItemTitle
                aria-controls={`consent-section-${sectionType}`}
                aria-expanded={expanded[sectionType]}
                expanded={expanded[sectionType]}
                onClick={() =>
                  setExpanded((map) => ({
                    ...map,
                    [sectionType]: !map[sectionType],
                  }))
                }
              >
                <ChevronRightSmall />
                <span>{section.name}</span>
              </ConsentItemTitle>
              {section.displayToggle && (
                <Toggle
                  name={sectionType}
                  checked={!!preferences[sectionType]}
                  disabled={section.disabled}
                  onChange={({ target }) => {
                    setPreferences({
                      [target.name]: target.checked,
                    });
                  }}
                />
              )}
            </ConsentItemHeader>
            <Expandable
              id={`consent-section-${sectionType}`}
              isExpanded={expanded[sectionType]}
            >
              <ConsentItemDescription>
                {section.description}
              </ConsentItemDescription>
            </Expandable>
          </ConsentItem>
        );
      })}
    </ConsentList>
  );
};

const ConsentManagerDialog = ({
  destinations,
  preferences,
  setPreferences,
  saveConsent,
  resetPreferences,
}: {
  destinations: Destination[];
  preferences: CategoryPreferences;
  setPreferences: (newPreferences: CategoryPreferences) => void;
  saveConsent: (
    newPreferences?: boolean | CategoryPreferences | undefined,
    shouldReload?: boolean | undefined
  ) => void;
  resetPreferences: () => void;
}): JSX.Element => {
  const [showSettings, setShowSettings] = useState(false);
  const { setDisplayConsentManager, locale, setInitializedConsent } =
    useContext(AppCtx);
  useLockBodyScroll(showSettings);

  return (
    <DialogWrapper
      data-testid="consent-manager-banner"
      variant={showSettings ? "settings" : "banner"}
    >
      <Dialog variant={showSettings ? "settings" : "banner"}>
        <DialogHeader
          as="header"
          variant={showSettings ? "settings" : "banner"}
        >
          <DialogTitle as="h3" variant="h3">
            {showSettings ? (
              <Trans>consent_manager.settings_title</Trans>
            ) : (
              <Trans>consent_manager.banner_title</Trans>
            )}
          </DialogTitle>
          {showSettings && (
            <CloseButton
              onClick={() => {
                setShowSettings(false);
                resetPreferences();
              }}
            />
          )}
          {showSettings && (
            <DialogDescription data-testid="consent-manager-settings-description">
              <p>
                <Trans>consent_manager.settings_description</Trans>{" "}
              </p>
              <HyperLink
                asLink
                data-testid="show-privacy-statement"
                href={getI18nRoute({ route: "/privacy-statement", locale })}
                hasUnderline
                variant="body.source.semibold14"
              >
                <Trans>consent_manager.privacy_and_cookie_statement</Trans>
              </HyperLink>
            </DialogDescription>
          )}
        </DialogHeader>
        <DialogBody data-testid="consent-manager-body">
          {!showSettings && (
            <>
              <p>
                <Trans>consent_manager.body</Trans>{" "}
              </p>
              <HyperLink
                hasUnderline
                variant="body.source.semibold14"
                onClick={() => setShowSettings(true)}
              >
                <Trans>consent_manager.manage_your_preferences</Trans>
              </HyperLink>
            </>
          )}
          {showSettings && (
            <ConsentSettings
              destinations={destinations}
              preferences={preferences}
              setPreferences={setPreferences}
            />
          )}
        </DialogBody>
        <DialogFooter variant={showSettings ? "settings" : "banner"}>
          {!showSettings && (
            <>
              <Button
                data-testid="consent-manager-reject-btn"
                variant="secondary"
                onClick={() => {
                  saveConsent({
                    functional: true,
                    analytics: true,
                    marketing: false,
                  });
                  setInitializedConsent(false);
                  setDisplayConsentManager(false);
                }}
              >
                <Trans>consent_manager.reject_button</Trans>
              </Button>
              <Button
                data-testid="consent-manager-accept-btn"
                variant="primary"
                onClick={() => {
                  saveConsent({
                    functional: true,
                    analytics: true,
                    marketing: true,
                  });
                  setInitializedConsent(true);
                  setDisplayConsentManager(false);
                }}
              >
                <Trans>consent_manager.accept_all</Trans>
              </Button>
            </>
          )}
          {showSettings && (
            <Button
              data-testid="consent-manager-save-btn"
              fullWidth
              variant="primary"
              onClick={() => {
                saveConsent(preferences);
                if (
                  preferences.analytics ||
                  preferences.marketing ||
                  preferences.functional
                ) {
                  setInitializedConsent(true);
                }
                setDisplayConsentManager(false);
              }}
            >
              <Trans>consent_manager.save_settings_button</Trans>
            </Button>
          )}
        </DialogFooter>
      </Dialog>
    </DialogWrapper>
  );
};

/**
 * Source: https://developers.google.com/tag-platform/security/guides/consent?consentmode=basic#tag-manager
 */
// eslint-disable-next-line prettier/prettier, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

export const ConsentManagerBanner = (): JSX.Element | null => {
  const { displayConsentManager, locale, setConsentUpdated } =
    useContext(AppCtx);
  const { consentStatusUpdated } = useSegment();

  const shouldRequireConsent = useCallback((): boolean => {
    const storeRequiresConsent = !CONSENT_NOT_REQUIRED_LOCALES.includes(locale);
    const isIOSApp =
      typeof webkit !== "undefined" &&
      typeof (webkit as { messageHandlers?: { track?: any } }).messageHandlers
        ?.track !== "undefined";

    const isAndroidApp = typeof segment_webview !== "undefined";
    return (
      storeRequiresConsent &&
      displayConsentManager &&
      !(isIOSApp || isAndroidApp)
    );
  }, [displayConsentManager, locale]);

  /**
   * Set the default consent preferences on Google Tag Manager
   * This function will push a dataLayer event to set the default consent preferences
   * With that, we can start tracking the user's consent preferences in Google Tag Manager
   * If any consent is given before, this function will read it from cookie
   * and update the consent preferences on Google Tag Manager.
   */
  const setDefaultConsentOnGTM = (): void => {
    if (window.dataLayer) {
      const cookiePreferences = Cookies.getJSON(COOKIE_NAME) as
        | { custom?: CategoryPreferences }
        | undefined;
      if (cookiePreferences && cookiePreferences.custom) {
        updateConsentOnGTM(cookiePreferences.custom);
      }
    }
  };

  const updateConsentOnGTM = (
    newPreferences: CategoryPreferences,
    onSave?: boolean
  ): void => {
    if (window.dataLayer) {
      const gtmScript = document.createElement("script");
      gtmScript.async = true;
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${
        process.env.GOOGLE_TAG_MANAGER_CONTAINER_ID || ""
      }`;

      gtmScript.onload = () => {
        if (typeof gtag === "function") {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          gtag("consent", "update", {
            ad_user_data: newPreferences.marketing ? "granted" : "denied",
            ad_personalization: newPreferences.marketing ? "granted" : "denied",
            ad_storage: newPreferences.marketing ? "granted" : "denied",
            analytics_storage: newPreferences.analytics ? "granted" : "denied",
          });

          setConsentUpdated(true);

          if (onSave) {
            void consentStatusUpdated(newPreferences);
          }
        } else {
          console.error("gtag function is not available.");
        }
      };

      const firstScript = document.getElementsByTagName("script")[0];
      if (firstScript.parentNode) {
        firstScript.parentNode.insertBefore(gtmScript, firstScript);
      }
    }
  };

  /**
   * Wrapper function to add Segment tracking to the saveConsent function
   * This function will push a dataLayer event to track the user's consent preferences
   * With that, we can track the user's consent preferences in Google Tag Manager
   * @param saveConsent - The saveConsent function from the ConsentManagerBuilder
   * @param newPreferences - The new preferences to save
   * @param shouldReload - Whether the page should be reloaded after saving the preferences
   * @return void
   */
  const saveConsentWrapper = (
    saveConsent: (
      newPreferences?: CategoryPreferences | boolean,
      shouldReload?: boolean
    ) => void,
    newPreferences?: CategoryPreferences | boolean,
    shouldReload?: boolean
  ): void => {
    saveConsent(newPreferences, shouldReload);

    if (
      typeof newPreferences !== "boolean" &&
      newPreferences &&
      window.dataLayer
    ) {
      const onSave = true;
      updateConsentOnGTM(newPreferences, onSave);
    }
  };

  useEffect(() => {
    setDefaultConsentOnGTM();
  }, []);

  if (!process.env.HIGHTOUCH_WRITE_KEY) return null;

  return (
    <ConsentManagerBuilder
      cookieName={COOKIE_NAME}
      writeKey={process.env.HIGHTOUCH_WRITE_KEY}
      options={{
        apiHost: "eu-west-1.hightouch-events.com",
      }}
      shouldRequireConsent={shouldRequireConsent}
      mapCustomPreferences={handleMapCustomPreferences}
    >
      {({
        destinations,
        preferences,
        isConsentRequired,
        setPreferences,
        saveConsent,
        resetPreferences,
      }) => {
        if (isConsentRequired && Object.keys(preferences).length === 0) {
          setPreferences({
            functional: true,
            analytics: false,
            marketing: false,
          });
        }

        return displayConsentManager && isConsentRequired ? (
          <ConsentManagerDialog
            destinations={destinations}
            preferences={preferences}
            setPreferences={setPreferences}
            saveConsent={(newPreferences, shouldReload) => {
              saveConsentWrapper(saveConsent, newPreferences, shouldReload);
            }}
            resetPreferences={resetPreferences}
          />
        ) : (
          // Returning a fragment because `null` doesn't pass typechecking
          <></>
        );
      }}
    </ConsentManagerBuilder>
  );
};
