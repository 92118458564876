export const categories = ["functional", "analytics", "marketing"] as const;

export type Categories = (typeof categories)[number];

export const MARKETING_AND_ANALYTICS_CATEGORIES = [
  "A/B Testing",
  "Analytics",
  "Attribution",
  "Email",
  "Enrichment",
  "Heatmaps & Recordings",
  "Raw Data",
  "Realtime Dashboards",
  "Referrals",
  "Surveys",
  "Video",
  "Other",
] as const;

export const ADVERTISING_CATEGORIES = ["Advertising"] as const;

export const FUNCTIONAL_CATEGORIES = [
  "CRM",
  "Customer Success",
  "Deep Linking",
  "Helpdesk",
  "Livechat",
  "Performance Monitoring",
  "Personalization",
  "SMS & Push Notifications",
  "Security & Fraud",
] as const;

export type MarketingAndAnalyticsCategory =
  (typeof MARKETING_AND_ANALYTICS_CATEGORIES)[number];

export type FunctionalCategory = (typeof FUNCTIONAL_CATEGORIES)[number];

export type AdvertisingCategory = (typeof ADVERTISING_CATEGORIES)[number];

export const isMarketingAndAnalyticsCategory = (
  value: string
): value is MarketingAndAnalyticsCategory =>
  MARKETING_AND_ANALYTICS_CATEGORIES.includes(
    value as MarketingAndAnalyticsCategory
  );

export const isFunctionalCategory = (
  value: string
): value is FunctionalCategory =>
  FUNCTIONAL_CATEGORIES.includes(value as FunctionalCategory);

export const isAdvertisingCategory = (
  value: string
): value is AdvertisingCategory =>
  ADVERTISING_CATEGORIES.includes(value as AdvertisingCategory);

export const CONSENT_NOT_REQUIRED_LOCALES = ["en-us"];
